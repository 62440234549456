import React, { useMemo } from 'react';

import { Table } from '../../../../components/table/Table';
import { TableColumn } from '../../../../components/table/TableTypes';
import { SubmissionGiCalcDataOutDto } from '@app/swagger-types';
import { renderDollarAmount } from '@app/utils/currency.util';

interface Props {
  data: SubmissionGiCalcDataOutDto;
  pdf?: boolean;
}

export const AwsAlternativeResultsTable: React.FC<Props> = ({ pdf, data }) => {
  const tableData = useMemo(() => [data], [data]);

  const cols: TableColumn<SubmissionGiCalcDataOutDto>[] = [
    {
      headerName: 'Score',
      field: 'stig',
      align: 'center',
    },
    {
      headerName: 'Predicted Losses',
      field: 'predictedLosses',
      align: 'center',
      render: ({ predictedLosses }) => renderDollarAmount(predictedLosses),
    },
  ];

  return <Table title="Alternative Results:" cols={cols} tableData={tableData} pdf={pdf} hidePageSize />;
};
