export enum ERole {
  ALL_ADMINISTRATOR = 'ALL_ADMINISTRATOR',
  INSURATE_USER_ADMIN = 'INSURATE_USER_ADMIN',
  USISA_USER_ADMIN = 'USISA_USER_ADMIN',
  STIAPP_FULL_ACCESS = 'STIAPP_FULL_ACCESS',
  STI_VERSIONS_MENU = 'STI_VERSIONS_MENU',
  SUBMISSIONS = 'SUBMISSIONS',
  ALTERNATIVE_RESULTS = 'ALTERNATIVE_RESULTS',
  SUBMISSION_INSIGHTS = 'SUBMISSION_INSIGHTS',
}
export interface UserOutDto {
  username: string;
  email: string;
  groups: ERole[];
}
