import { Routes } from '@app/constants/routes';

import { MyRouteObject } from '@app/models/router';

import { AdminRoutes } from './admin/AdminRoutes';
// import { NotFoundPage } from './root-pages/NotFound';
import { TypedNavigate } from '@app/router';
import { RoadmapRoutes } from './roadmap/RoadmapRoutes';
import { UIKitRoutes } from './ui-kit/UIKitRoutes';
import { DevKitRoutes } from './dev-kit/DevKitRoutes';
import { ClaimRoutes } from '@app/modules/claims/ClaimsRoutes';
import { StiRoutes } from './sti/StiRoutes';
import { LossRatioRoutes } from './loss-ratio/LossRatioRoutes';
import { SubmissionRoutes } from './submission/SubmissionRoutes';
import { HAS_ACCESS_TO_STI } from '@app/environment/typed-env';
import { DashboardRoutes } from './dashboard/DashboardRoutes';
import { RootLayout } from './RootLayout';

export const RootRoutes: MyRouteObject[] = [
  {
    path: Routes.index,
    element: <RootLayout />,
    children: [
      AdminRoutes,
      ClaimRoutes,
      RoadmapRoutes,
      UIKitRoutes,
      DevKitRoutes,
      HAS_ACCESS_TO_STI ? StiRoutes : DashboardRoutes,
      LossRatioRoutes,
      SubmissionRoutes,
    ],
  },
  // IF YOU PREFER 404 ROOT PAGES => (require manual redirect after sign-in/sign-up/logout in responding pages/actions)
  // { path: '*', element: <NotFoundPage /> },
  {
    index: true,
    path: '*',
    element: <TypedNavigate to={Routes.sti.index} />,
    errorElement: <TypedNavigate to={Routes.sti.index} />,
  },
];
