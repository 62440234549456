import React from 'react';

import { Table } from '../../../components/table/Table';
import { TableColumn } from '../../../components/table/TableTypes';
import { CalcResponseState } from '../types/calc.types';
import { renderDollarAmount } from '@app/utils/currency.util';
import { calculateExpectedClaims, getColorByFinalCrDB } from '../utils/sti.utils';
import { isNumber } from 'lodash';
import { Tooltip } from '@app/components/tooltip/Tooltip';
import { UNCHANGEABLE_CLASS_CODES } from '../const/calc.const';

interface Props {
  states: CalcResponseState[];
  pdf?: boolean;
  originalCrDb?: number;
  overrideStiCrDb?: number;
  onClickStiCrDb?: () => void;
  originalStates?: CalcResponseState[];
}

export const CalcStatesTable: React.FC<Props> = ({
  states,
  pdf,
  originalCrDb,
  overrideStiCrDb,
  onClickStiCrDb,
  originalStates,
}) => {
  const cols: TableColumn<CalcResponseState>[] = [
    {
      headerName: 'State',
      field: 'code',
      align: 'center',
    },
    {
      headerName: 'Class Code',
      field: 'classCode',
      align: 'center',
    },
    {
      headerName: 'Payroll',
      field: 'payroll',
      align: 'center',
      minWidth: 120,
      render: ({ payroll }) => renderDollarAmount(payroll, 0),
    },
    {
      headerName: 'Safe-TierPremium',
      headerJSX: (
        <>
          Safe-Tier
          <br />
          Premium
        </>
      ),
      field: 'safeTierPremium',
      align: 'center',
      minWidth: 120,
      render: ({ safeTierPremium, expectedClaims, classCode }, index) => {
        if (isNumber(overrideStiCrDb) && isNumber(originalCrDb) && !UNCHANGEABLE_CLASS_CODES.includes(classCode)) {
          const newExpectedClaims = calculateExpectedClaims(
            originalStates?.[index].expectedClaims ?? expectedClaims,
            originalCrDb,
            overrideStiCrDb
          );

          return renderDollarAmount(newExpectedClaims / 0.4, 0);
        }
        return renderDollarAmount(safeTierPremium, 0);
      },
    },
    {
      headerName: 'STI CR/DB',
      field: 'stiCrDB',
      align: 'center',
      bold: true,
      render: ({ stiCrDB, classCode }) => {
        const stiCrDBNum: number = overrideStiCrDb ?? Number(stiCrDB);

        if (UNCHANGEABLE_CLASS_CODES.includes(classCode)) {
          return (
            <Tooltip title={`Value for class code ${classCode} is always 0%`} placement="top">
              <button
                className="cursor-not-allowed bg-transparent font-semibold"
                style={{ color: getColorByFinalCrDB(Number(stiCrDB)) }}
                disabled
              >{`${stiCrDB}%`}</button>
            </Tooltip>
          );
        }

        return (
          <button
            className="cursor-pointer bg-transparent font-semibold"
            onClick={onClickStiCrDb}
            style={{ color: getColorByFinalCrDB(stiCrDBNum) }}
          >{`${stiCrDBNum}%`}</button>
        );
      },
    },
    {
      headerName: 'Predicted Losses',
      headerJSX: (
        <>
          Predicted
          <br />
          Losses
        </>
      ),
      field: 'expectedClaims',
      align: 'center',
      minWidth: 120,
      render: ({ expectedClaims, classCode }, index) => {
        if (isNumber(overrideStiCrDb) && isNumber(originalCrDb) && !UNCHANGEABLE_CLASS_CODES.includes(classCode)) {
          const newExpectedClaims = calculateExpectedClaims(
            originalStates?.[index].expectedClaims ?? expectedClaims,
            originalCrDb,
            overrideStiCrDb
          );

          return renderDollarAmount(newExpectedClaims, 0);
        }

        return renderDollarAmount(expectedClaims, 0);
      },
    },
    {
      headerName: 'Premium',
      field: 'finalPremium',
      align: 'center',
      bold: true,
      minWidth: 120,
      render: ({ finalPremium, classCode }) =>
        isNumber(overrideStiCrDb) && !UNCHANGEABLE_CLASS_CODES.includes(classCode)
          ? '-'
          : renderDollarAmount(finalPremium, 0),
    },
    {
      headerName: 'Final CR/DB',
      field: 'finalCrDB',
      align: 'center',
      bold: true,
      minWidth: 100,
      render: ({ finalCrDB, classCode }) =>
        isNumber(overrideStiCrDb) && !UNCHANGEABLE_CLASS_CODES.includes(classCode) ? '-' : `${finalCrDB}`,
    },
    {
      headerName: 'Net Final Rate',
      field: 'netFinalRate',
      align: 'center',
      bold: true,
      minWidth: 100,
      render: ({ netFinalRate, classCode }) =>
        isNumber(overrideStiCrDb) && !UNCHANGEABLE_CLASS_CODES.includes(classCode) ? '-' : `${netFinalRate}`,
    },
    {
      headerName: 'Filling',
      field: 'filing',
      align: 'center',
      minWidth: 100,
      render: ({ filing, classCode }) =>
        isNumber(overrideStiCrDb) && !UNCHANGEABLE_CLASS_CODES.includes(classCode) ? '-' : filing,
    },
  ];

  return <Table cols={cols} tableData={states} pdf={pdf} hidePageSize />;
};
