import React, { useMemo } from 'react';

import { Table } from '../../../../components/table/Table';
import { TableColumn } from '../../../../components/table/TableTypes';
import { renderDollarAmount } from '@app/utils/currency.util';
import { AwsCalcOutDto } from '../../types/calc.types';

type RowType = Pick<AwsCalcOutDto, 'sti' | 'projectedLosses'>;

type Props = Pick<AwsCalcOutDto, 'sti' | 'projectedLosses'> & {
  pdf?: boolean;
};

export const AwsStiTable: React.FC<Props> = ({ pdf, sti, projectedLosses }) => {
  const data: RowType[] = useMemo(() => [{ sti, projectedLosses }], [sti, projectedLosses]);

  const cols: TableColumn<RowType>[] = [
    {
      headerName: 'STI',
      field: 'sti',
      headAlign: 'left',
      align: 'left',
      bold: true,
      width: 150,
    },
    {
      headerName: 'Predicted Losses',
      field: 'projectedLosses',
      headAlign: 'center',
      align: 'center',
      bold: true,
      width: 150,
      render: ({ projectedLosses }) => renderDollarAmount(projectedLosses),
    },
  ];

  return <Table title="SafeTier Results:" cols={cols} tableData={data} pdf={pdf} hidePageSize />;
};
