import { useState, useRef, PropsWithChildren, FC } from 'react';

import clsx from 'clsx';
import { Button, Typography } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import pluralize from 'pluralize';

import { getFilesAsync } from '../../utils/file.utils';
import { FileUploadInput } from '../file-upload-input/FileUploadInput';

import styles from './FileDropZoneWrapper.module.css';
import { CircularProgress } from '../CircularProgress';

export type FileDropZoneWrapperProps = {
  className?: string;
  hoverClassName?: string;
  allowedTypes?: string[];
  multiple?: boolean;
  onChange?: (data: File) => void;
  onMultipleChange?: (data: File[]) => void;
  onClick?: () => void;
  onNotAllowed?: (file?: File) => void;
  disabled?: boolean;
  loading?: boolean;
  accept?: string;
  text?: string;
};

export const FileDropZoneWrapper: FC<PropsWithChildren<FileDropZoneWrapperProps>> = ({
  className,
  hoverClassName,
  allowedTypes,
  children,
  multiple,
  onNotAllowed,
  onChange,
  onMultipleChange,
  onClick,
  disabled,
  loading,
  accept,
  text = 'Drag and drop here or',
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dropHover, setDropHover] = useState(false);

  const pickFileHandler = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
    onClick && onClick();
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
    setDropHover(true);
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDropHover(false);
  };

  const onFileUpload = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (multiple && onMultipleChange) {
      setDropHover(false);

      const files = await getFilesAsync(e.dataTransfer);
      for (let i = 0; i < files.length; i++) {
        if (allowedTypes && allowedTypes.indexOf(files[i]?.type || '') === -1) {
          e.dataTransfer.clearData();
          if (onNotAllowed) {
            onNotAllowed(files[i]);
          }
          return;
        }
      }

      return onMultipleChange(files);
    }
    const file = e.dataTransfer.files[0];
    if (file && onChange) {
      setDropHover(false);
      if (allowedTypes && allowedTypes.indexOf(file.type) === -1) {
        e.dataTransfer.clearData();
        if (onNotAllowed) {
          onNotAllowed(file);
        }
        return;
      }
      onChange(file);
    }
  };

  return (
    <div
      className={clsx(
        // styles.DropWrapper,
        {
          [styles.DropWrapper]: true,
          [styles.DropHover]: dropHover,
          [styles.disabled]: disabled,
          [styles.loading]: loading,
          ...(hoverClassName ? { [hoverClassName]: dropHover } : {}),
        },
        className
      )}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={onFileUpload}
      aria-hidden
    >
      {children || (
        <div className="flex flex-col items-center justify-start gap-1">
          {loading ? <CircularProgress size={24} /> : <CloudUploadOutlinedIcon className="w-[35px] text-black/50" />}
          <Typography className="text-s text-black" align="center">
            {text}
          </Typography>
          <Button
            disableRipple
            variant="text"
            className="bg-transparent text-s normal-case text-blue-400 hover:underline"
            onClick={pickFileHandler}
            disabled={loading || disabled}
          >
            {loading ? 'Uploading' : `Choose ${pluralize('file', multiple ? 2 : 1)}`}
          </Button>
        </div>
      )}
      <FileUploadInput
        disabled={disabled || loading}
        multiple={multiple}
        onMultipleChange={onMultipleChange}
        onChange={onChange}
        onNotAllowed={onNotAllowed}
        allowedTypes={allowedTypes}
        ref={fileInputRef}
        accept={accept}
      />
    </div>
  );
};
