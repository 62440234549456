import { FC } from 'react';
import { VerifyLossRunDataTable } from '../components/VerifyLossRunDataTable';
import { Card, Skeleton, Typography } from '@mui/material';
import { SubmissionLossReviewCarrierTable } from '../components/SubmissionLossReviewCarrierTable';
import { useGetSubmissionLossReviewQuery } from '../api/submission.api.hooks';

interface Props {
  submissionId: string;
}

export const SubmissionLossReviewTab: FC<Props> = ({ submissionId }) => {
  const { data: lossReviewData, isFetching: isFetchingLossReview } = useGetSubmissionLossReviewQuery(submissionId, {
    refetchOnMount: 'always',
  });

  if (!lossReviewData && isFetchingLossReview) {
    return (
      <div className="flex flex-col gap-6">
        <Skeleton height={360} variant="rectangular" className="rounded" />
        <Skeleton height={500} variant="rectangular" className="rounded" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <VerifyLossRunDataTable
        submissionId={submissionId}
        lossReviewData={lossReviewData}
        isFetching={isFetchingLossReview}
      />
      <Card className="flex w-full flex-col gap-2.5 rounded-lg bg-white p-6 shadow-card">
        <Typography className="py-4 text-xxxl">All Claims</Typography>
        {lossReviewData?.carriers.length ? (
          lossReviewData.carriers.map((claimInfo, index) => (
            <SubmissionLossReviewCarrierTable
              containerClassName={index > 0 ? 'mt-6' : ''}
              claimInfo={claimInfo}
              key={index}
            />
          ))
        ) : (
          <div className="flex h-36 items-center justify-center p-10">
            <Typography className="font-medium italic text-gray-600">No claims extracted</Typography>
          </div>
        )}
      </Card>
    </div>
  );
};
