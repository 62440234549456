import React from 'react';

import { Table } from '../../../components/table/Table';
import { EBodyCellBG, TableColumn } from '../../../components/table/TableTypes';
import { FullCalcOutDto } from '../types/calc.types';
import { renderDollarAmount } from '@app/utils/currency.util';
import { isNumber, reduce } from 'lodash';
import { calculateExpectedClaims } from '../utils/sti.utils';
import { UNCHANGEABLE_CLASS_CODES } from '../const/calc.const';

type Props = Pick<
  FullCalcOutDto,
  'sti' | 'confidence' | 'stpPremium' | 'totalExpClaims' | 'finalPremium' | 'assocDues'
> & {
  originalStates: FullCalcOutDto['states'];
  pdf?: boolean;
  originalCrDb?: number;
  overrideStiCrDb?: number;
};

export const CalcTable: React.FC<Props> = ({ pdf, overrideStiCrDb, originalCrDb, originalStates, ...result }) => {
  const sumOfNewExpectedClaims =
    isNumber(overrideStiCrDb) && isNumber(originalCrDb)
      ? reduce(
          originalStates.map((item) =>
            UNCHANGEABLE_CLASS_CODES.includes(item.classCode)
              ? item.expectedClaims
              : calculateExpectedClaims(item.expectedClaims, originalCrDb, overrideStiCrDb)
          ),
          (sum, num) => sum + num
        ) || 0
      : 0;

  const cols: TableColumn<
    Pick<FullCalcOutDto, 'sti' | 'confidence' | 'stpPremium' | 'totalExpClaims' | 'finalPremium' | 'assocDues'>
  >[] = [
    {
      headerName: 'STI',
      field: 'sti',
      align: 'center',
    },
    {
      headerName: 'Confidence',
      field: 'confidence',
      align: 'center',
      render: ({ confidence }) => `${confidence}%`,
    },
    {
      headerName: 'STP Premium',
      field: 'stpPremium',
      align: 'center',
      minWidth: 120,
      render: ({ stpPremium }) =>
        isNumber(overrideStiCrDb)
          ? renderDollarAmount(sumOfNewExpectedClaims / 0.4, 0)
          : renderDollarAmount(stpPremium, 0),
    },
    {
      headerName: 'Total Predicted Losses',
      field: 'totalExpClaims',
      align: 'center',
      minWidth: 120,
      render: ({ totalExpClaims }) =>
        isNumber(overrideStiCrDb)
          ? renderDollarAmount(sumOfNewExpectedClaims, 0)
          : renderDollarAmount(totalExpClaims, 0),
    },
    {
      headerName: 'Recommended Premium',
      field: 'finalPremium',
      align: 'center',
      bold: true,
      bodyCellBg: EBodyCellBG.YELLOW,
      minWidth: 120,
      render: ({ finalPremium }) => (isNumber(overrideStiCrDb) ? '-' : renderDollarAmount(finalPremium, 0)),
    },
  ];

  return <Table cols={cols} tableData={[result]} pdf={pdf} hidePageSize />;
};
