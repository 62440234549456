import { Table, TableColumn } from '@app/components/table';
import { FORMAT } from '@app/constants/formats';
import { clsxm } from '@app/styles/clsxm';
import { SubmissionLossClaimOutDto, SubmissionLossReviewCarrierOutDto } from '@app/swagger-types';
import { parseSortParam } from '@app/utils/api.util';
import { renderDollarAmount } from '@app/utils/currency.util';
import { convertUTCToLocalDate } from '@app/utils/date.utils';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { orderBy } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { SubmissionUtils } from '../utils/submission.utils';
import { TruncatedText } from '@app/components/truncated-text/TruncatedText';

type SubmissionLossReviewCarrierRowType = Omit<SubmissionLossClaimOutDto, 'status' | 'type'> & {
  status: SubmissionLossClaimOutDto['status'] | string;
  type: SubmissionLossClaimOutDto['type'] | string;
  isTotal?: boolean;
};

interface Props {
  claimInfo: SubmissionLossReviewCarrierOutDto;
  containerClassName?: string;
}

export const SubmissionLossReviewCarrierTable: FC<Props> = ({ claimInfo, containerClassName }) => {
  const [sort, setSort] = useState('');

  const {
    name,
    effectiveDateStart,
    effectiveDateEnd,
    claims,
    totalMedical,
    totalIndemnity,
    totalExpense,
    totalReserves,
    totalIncurred,
  } = claimInfo;

  const cols: TableColumn<SubmissionLossReviewCarrierRowType>[] = useMemo(
    () => [
      {
        headerName: 'Date of Loss',
        field: 'dateOfLoss',
        align: 'left',
        width: 120,
        render: ({ dateOfLoss, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold', 'text-wrap whitespace-normal')}>
            {isTotal ? dateOfLoss : format(convertUTCToLocalDate(dateOfLoss), FORMAT.DATE_YEAR)}
          </span>
        ),
      },
      {
        headerName: 'Status',
        field: 'status',
        align: 'center',
        width: 80,
        render: ({ status }) => SubmissionUtils.getFriendlySubmissionClaimStatusLabel(status),
      },
      {
        headerName: 'Type',
        field: 'type',
        align: 'center',
        width: 80,
        render: ({ type, isTotal }) => <span className={clsxm(isTotal && 'font-semibold')}>{type}</span>,
      },
      {
        headerName: 'Medical',
        field: 'medical',
        align: 'right',
        width: 120,
        render: ({ medical, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{renderDollarAmount(medical, 2)}</span>
        ),
      },
      {
        headerName: 'Indemnity',
        field: 'indemnity',
        align: 'right',
        width: 120,
        render: ({ indemnity, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{renderDollarAmount(indemnity, 2)}</span>
        ),
      },
      {
        headerName: 'Expense',
        field: 'expense',
        align: 'right',
        width: 120,
        render: ({ expense, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{renderDollarAmount(expense, 2)}</span>
        ),
      },
      {
        headerName: 'Incurred',
        field: 'incurred',
        align: 'right',
        width: 120,
        render: ({ incurred, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{renderDollarAmount(incurred, 2)}</span>
        ),
      },
      {
        headerName: 'Reserves',
        field: 'reserves',
        align: 'right',
        width: 120,
        render: ({ reserves, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{renderDollarAmount(reserves, 2)}</span>
        ),
      },
      {
        headerName: 'Details',
        field: 'details',
        align: 'left',
        minWidth: 140,
        disableSort: true,
        render: ({ details }) => (details ? <TruncatedText text={details} /> : null),
      },
    ],
    []
  );

  const tableData: SubmissionLossReviewCarrierRowType[] = useMemo(() => {
    let data = claims || [];

    const sortParam = sort.length ? parseSortParam(sort) : null;

    if (sortParam?.field && sortParam.order) {
      data = orderBy(data, [sortParam?.field], [sortParam.order]);
    }

    if (data.length) {
      return [
        ...data,
        // TOTAL Row
        {
          isTotal: true,
          dateOfLoss: name ? `${name} Totals` : 'Totals',
          status: '',
          type: `Claims: ${data.length}`,
          medical: totalMedical ?? 0,
          indemnity: totalIndemnity ?? 0,
          expense: totalExpense ?? 0,
          reserves: totalReserves ?? 0,
          incurred: totalIncurred ?? 0,
          details: '',
        },
      ];
    }

    return data;
  }, [claims, totalMedical, totalIndemnity, totalExpense, totalReserves, totalIncurred, name, sort]);

  return (
    <div className={clsxm('flex flex-col gap-3', containerClassName)}>
      <div className="flex w-fit flex-col gap-3 border-b border-b-primary py-1.5">
        <div className="flex items-center gap-3">
          {name ? <Typography className="text-xl">{name}</Typography> : null}
          {effectiveDateStart && effectiveDateEnd ? (
            <Typography className="text-xl text-black/60">
              {format(convertUTCToLocalDate(effectiveDateStart), FORMAT.DATE_YEAR)} -{' '}
              {format(convertUTCToLocalDate(effectiveDateEnd), FORMAT.DATE_YEAR)}
            </Typography>
          ) : null}
        </div>
      </div>
      <Table
        tableLayout="fixed"
        cols={cols}
        tableData={tableData}
        hidePageSize
        currentSort={sort}
        onSortChanged={setSort}
        tableCellClassName="overflow-x-visible"
      />
    </div>
  );
};
