import clsx from 'clsx';
import { getYear, getMonth, startOfWeek, endOfWeek, isBefore, addDays } from 'date-fns';
import { range, capitalize, isNull } from 'lodash';
import React, { useMemo } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Typography } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.min.css';

import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import { useUncontrolled } from '@app/hooks/useUncontrolled.hook';
import { Select, OptionItem } from '@app/components/select';
import styles from './DatePicker.module.scss';

export type DatePickerProps = Omit<
  ReactDatePickerProps,
  'inline' | 'formatWeekDay' | 'renderCustomHeader' | 'dayClassName' | 'onChange'
> & {
  customWeekDayClassName?: string;
  customDayClassName?: string;
  customMonthClassName?: string;
  color?: 'dark' | 'white';
  size?: 'medium' | 'large';
  showPlaceholderText?: string;
  yearsRange?: number[];
  weekHighlights?: boolean;
  header?: React.ReactElement;
  footer?: React.ReactElement;
  onChange?(date: Date): void;
};

const stylesTyped: Record<string, any> = styles;

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getAvailableMonths = ({
  date,
  minDate,
  maxDate,
}: {
  date: Date;
  minDate?: Date | null;
  maxDate?: Date | null;
}) => {
  if (date.getFullYear() !== minDate?.getFullYear() && date.getFullYear() !== maxDate?.getFullYear()) {
    return months;
  }
  if (minDate || maxDate) {
    return months.slice(minDate?.getMonth(), maxDate?.getMonth());
  }
  return months;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  color = 'dark',
  size = 'medium',
  calendarClassName,
  selectsRange,
  yearsRange,
  selected,
  customWeekDayClassName,
  customMonthClassName,
  customDayClassName,
  highlightDates,
  weekHighlights,
  showPlaceholderText,
  onChange,
  header,
  footer,
  minDate,
  maxDate,
  showMonthYearPicker,
  ...rest
}) => {
  const [selectedState, handleDateChange] = useUncontrolled({
    value: selected,
    rule: (val) => !!val || isNull(val),
    onChange,
  });
  const years = useMemo(() => {
    if (yearsRange) {
      return yearsRange;
    }
    let minYear = 1900;
    let maxYear = getYear(new Date()) + 20;

    if (minDate) {
      minYear = getYear(minDate);
      maxYear = getYear(minDate) + 11;
    }

    if (maxDate) {
      maxYear = getYear(maxDate) + 1;
    }

    return range(minYear, maxYear, 1);
  }, [yearsRange, minDate, maxDate]);

  const highlightsArray = useMemo(() => {
    const highlights = [...(highlightDates || [])];
    if (selectedState) {
      highlights.push({
        [styles.SelectedDate]: [selectedState],
      });
    }

    if (weekHighlights && selectedState) {
      const start = startOfWeek(selectedState);
      const end = endOfWeek(selectedState);
      let dateItem = start;
      const range = [];

      while (isBefore(dateItem, end)) {
        range.push(dateItem);
        dateItem = addDays(dateItem, 1);
      }

      highlights.push({
        [styles.DayInWeek]: range,
      });
    }
    return highlights;
  }, [highlightDates, selectedState, weekHighlights]);

  return (
    <div className={styles.DatePickerWrapper}>
      <ReactDatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          decreaseYear,
          increaseYear,
        }) => (
          <>
            {header}
            <div className={styles.HeaderContainer}>
              <div className="flex w-full items-center justify-between gap-1">
                <IconButton
                  variant="transparent"
                  color="primary"
                  onClick={() => {
                    if (showMonthYearPicker) {
                      // handleDateChange(subYears(selected, 1));
                      decreaseYear();
                    } else {
                      decreaseMonth();
                    }
                  }}
                  disabled={prevMonthButtonDisabled}
                  className={styles.LeftArrow}
                  disableRipple
                >
                  <ChevronUpIcon />
                </IconButton>
                {!showMonthYearPicker && (
                  <Select
                    size="small"
                    className={styles.Select}
                    inputProps={{
                      variant: 'filled',
                    }}
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) => changeMonth(months.indexOf(String(value)))}
                    MenuProps={{
                      autoFocus: true,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                      elevation: 1,
                      disableScrollLock: true,
                    }}
                  >
                    {months.map((option) => (
                      <OptionItem
                        key={option}
                        value={option}
                        disabled={
                          !getAvailableMonths({ date, minDate, maxDate }).includes(option) &&
                          option !== months[getMonth(date)]
                        }
                      >
                        {option}
                      </OptionItem>
                    ))}
                  </Select>
                )}
                <Select
                  size="small"
                  inputProps={{
                    variant: 'filled',
                  }}
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(Number(value))}
                  className={styles.Select}
                  MenuProps={{
                    autoFocus: true,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    elevation: 1,
                    disableScrollLock: true,
                  }}
                >
                  {years.map((option) => (
                    <OptionItem key={option} value={option}>
                      {option}
                    </OptionItem>
                  ))}
                </Select>
                <IconButton
                  disableRipple
                  variant="transparent"
                  color="primary"
                  onClick={() => {
                    if (showMonthYearPicker) {
                      // handleDateChange(addYears(selected, 1));
                      increaseYear();
                    } else {
                      increaseMonth();
                    }
                  }}
                  disabled={nextMonthButtonDisabled}
                  className={styles.RightArrow}
                >
                  <ChevronUpIcon />
                </IconButton>
              </div>
            </div>
          </>
        )}
        highlightDates={highlightsArray}
        formatWeekDay={(day) => (
          <Typography
            className={clsx(
              styles.WeekDay,
              customWeekDayClassName,
              stylesTyped[capitalize(color)],
              stylesTyped[capitalize(size)]
            )}
            variant="subtitle1"
          >
            {day.substring(0, 3)}
          </Typography>
        )}
        calendarClassName={clsx(
          'border-none',
          showPlaceholderText ? styles.WithPlaceholder : null,
          stylesTyped[capitalize(color)],
          calendarClassName
        )}
        dayClassName={() => {
          return clsx(
            styles.DatePickerDay,
            customDayClassName,
            stylesTyped[capitalize(color)],
            stylesTyped[capitalize(size)]
          );
        }}
        monthClassName={() =>
          clsx(
            styles.DatePickerMonth,
            customMonthClassName,
            stylesTyped[capitalize(color)],
            stylesTyped[capitalize(size)]
          )
        }
        inline
        disabledKeyboardNavigation
        selected={selectedState}
        selectsRange={selectsRange}
        onChange={handleDateChange}
        minDate={minDate}
        maxDate={maxDate}
        showMonthYearPicker={showMonthYearPicker}
        {...rest}
      />
      {footer ? <div className="mx-5">{footer}</div> : null}
    </div>
  );
};
