import { FC } from 'react';

import { Table } from '../../../../components/table/Table';
import { TableColumn } from '../../../../components/table/TableTypes';
import { AwsResponseState } from '../../types/calc.types';
import { renderDollarAmount } from '@app/utils/currency.util';

interface Props {
  states: AwsResponseState[];
  pdf?: boolean;
}

export const AwsStatesTable: FC<Props> = ({ states, pdf }) => {
  const cols: TableColumn<AwsResponseState>[] = [
    {
      headerName: 'State',
      field: 'stateCode',
      align: 'center',
      width: 150,
    },
    {
      headerName: 'Class Code',
      field: 'ncciCode',
      align: 'center',
      width: 150,
    },
    {
      headerName: 'Payroll',
      field: 'payroll',
      headAlign: 'center',
      align: 'center',
      render: ({ payroll }) => renderDollarAmount(Number(payroll)),
      width: 150,
    },
    {
      headerName: 'Predicted Losses',
      headAlign: 'center',
      field: 'projectedLosses',
      align: 'center',
      bold: true,
      render: ({ projectedLosses }) => renderDollarAmount(Number(projectedLosses)),
      width: 150,
    },
    {
      headerName: 'CR/DB',
      field: 'finalCrDb',
      align: 'center',
      width: 150,
    },
    {
      headerName: 'Filling',
      field: 'filingType',
      align: 'center',
      width: 150,
    },
    {
      headerName: 'LC',
      field: 'lc',
      align: 'center',
      width: 150,
    },
    {
      headerName: 'LCM',
      field: 'lcm',
      align: 'center',
      width: 150,
    },
  ];

  return <Table title="State Breakdown:" cols={cols} tableData={states} pdf={pdf} hidePageSize />;
};
