import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { marked } from 'marked';
import { FC } from 'react';

import { SubmissionInsightApiDto } from '@app/swagger-types';

const CustomExpandAndCollapseIcon = () => (
  <Box
    sx={{
      '.Mui-expanded & > .collapsIconWrapper': {
        display: 'none',
      },
      '.expandIconWrapper': {
        display: 'none',
      },
      '.Mui-expanded & > .expandIconWrapper': {
        display: 'block',
      },
    }}
  >
    <div className="expandIconWrapper">
      <RemoveIcon />
    </div>
    <div className="collapsIconWrapper">
      <AddIcon />
    </div>
  </Box>
);

interface Props {
  insight: SubmissionInsightApiDto;
  index: number;
  isPanelExpanded: (panel: string) => boolean;
  handleChange: (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => void;
}

export const SubmissionInsightAccordion: FC<Props> = ({ insight, index, isPanelExpanded, handleChange }) => {
  const parsedAnswer = marked(insight.answer) as string;
  return (
    <Accordion
      sx={{ ':before': { display: 'none' } }}
      expanded={isPanelExpanded(`panel-${index}`)}
      className="rounded-md shadow-light"
      onChange={handleChange(`panel-${index}`)}
      key={insight.question}
    >
      <AccordionSummary
        className="font-semibold"
        expandIcon={<CustomExpandAndCollapseIcon />}
        aria-controls={`${index}-content`}
        id={`${index}-header`}
      >
        {insight.question}
      </AccordionSummary>
      <AccordionDetails className="border-none pb-4" dangerouslySetInnerHTML={{ __html: parsedAnswer }} />
    </Accordion>
  );
};
