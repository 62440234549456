import { FC } from 'react';

import { SubmissionFullAssessmentCalculator } from '@app/domain/sti/widgets/SubmissionFullAssessmentCalculator';
import { SubmissionFullAssessmentResult } from '@app/domain/sti/widgets/SubmissionFullAssessmentResult';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { useCalculateSubmissionBySti } from '../api/submission.api.hooks';
import { useIsMutating } from '@tanstack/react-query';
import { EMutationConfigName } from '@app/constants/mutation-config.const';

interface Props {
  submissionId: string;
}

export const SubmissionCalculatorStandardTab: FC<Props> = ({ submissionId }) => {
  const { onChangeFullCalcResult, setCalcEditing, fullCalcResult, isCalcEditing } =
    useSubmissionCalculatorFormsContext();

  const { mutateAsync, data } = useCalculateSubmissionBySti(
    submissionId,
    {},
    {
      onSuccess: (response) => {
        onChangeFullCalcResult(response);
        setCalcEditing(false);
      },
    }
  );

  const isLoading = Boolean(useIsMutating({ mutationKey: [EMutationConfigName.CALC_SUBMISSION_BY_STI, submissionId] }));

  return (
    <>
      {!isCalcEditing && fullCalcResult && fullCalcResult.data && !isLoading ? (
        <div className="flex w-full justify-center">
          <SubmissionFullAssessmentResult
            submissionId={submissionId}
            originalStates={fullCalcResult.originalStates}
            modelVersion={fullCalcResult.version?.model}
            originalCrDb={fullCalcResult.originalCrDb}
            responseId={fullCalcResult.responseId}
            warnings={fullCalcResult.warnings}
            payload={fullCalcResult.payload}
            setIsEditing={setCalcEditing}
            result={fullCalcResult.data}
          />
        </div>
      ) : (
        <SubmissionFullAssessmentCalculator
          calcSubmissionBySti={mutateAsync}
          submissionId={submissionId}
          fullCalcResult={data}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
