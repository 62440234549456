import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { NcciOutDto } from '../../../swagger-types';

import { Autocomplete } from '@app/components/fields/autocomplete/Autocomplete';
import { ENcciType } from '../api/sti-api';
import { useGetAllNCCIs } from '../api/sti-api.hooks';

interface Props<T extends FieldValues> {
  label?: string;
  disabled?: boolean;
  focus?: boolean;
  aws?: boolean;
  name: FieldPath<T>;
  control: Control<T>;
}

export const PayrollClassCodeAutocomplete = <T extends FieldValues>({
  name,
  label,
  control,
  disabled,
  aws,
  focus = false,
}: Props<T>) => {
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    setOpen(focus);
    if (focus) {
      setTimeout(() => {
        // click to set focus, '.focus()' does not work
        inputRef.current?.click();
      }, 1);
    }
  }, [focus]);

  const { data: nccisRes, isLoading } = useGetAllNCCIs({
    type: aws ? ENcciType.AWS : ENcciType.FULL,
  });

  const data = useMemo(() => nccisRes?.result || [], [nccisRes?.result]);

  const renderOptionLabel = useCallback((option: NcciOutDto) => {
    if (!option.ncci) {
      return option.classificationWording;
    }
    return `${option.ncci}: ${option.classificationWording}`;
  }, []);

  const options: NcciOutDto[] = useMemo(() => data, [data]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete<NcciOutDto>
          {...field}
          ref={(e: HTMLDivElement | null) => {
            field.ref(e);
            inputRef.current = e;
          }}
          InputProps={{
            placeholder: 'Class Code',
            label,
            error: Boolean(error),
            inputSize: 'large',
          }}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={(event, value) => {
            if (value?.ncci) {
              field.onChange(value);
            }
          }}
          getOptions={async () => options}
          forceOptions={options}
          loading={isLoading}
          fetchOnOpen
          browserAutocompleteOff
          getOptionLabel={renderOptionLabel}
          noOptionsText="Class code not found"
          autoHighlight
          autoSelect
          fullWidth
          disabled={disabled}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterOptions={(options, { getOptionLabel, inputValue }) =>
            options.filter((option) => getOptionLabel(option).toLowerCase().includes(inputValue.toLowerCase()))
          }
        />
      )}
    />
  );
};
