import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { FieldPath, useFieldArray, useFormContext } from 'react-hook-form';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { Paper, TextField, Typography } from '@mui/material';
import { nanoid } from 'nanoid';

import { useSubmissionCalculatorFormsContext } from '@app/domain/submission/contexts/SubmissionCalculatorFormsContext';
import { ReactHookFormPatternField } from '@app/components/fields/react-hook-form-fields/ReactHookFormPatternField';
import { ReactHookFormNumberField } from '@app/components/fields/react-hook-form-fields/ReactHookFormNumberField';
import { SubmissionCalculatorHeading } from '@app/domain/submission/components/SubmissionCalculatorHeading';
import { PayrollClassCodeAutocomplete } from '@app/domain/sti/components/PayrollClassCodeAutocomplete';
import { useUpdateSubmissionStiAwsPrefill } from '@app/domain/submission/api/submission.api.hooks';
import { AwsCalcValidationSchema, AwsStateInputSchemaType } from '@app/domain/sti/forms/calc.form';
import { createAwsCalcDto, createAwsPrefillDto } from '@app/domain/sti/api/sti-api.utils';
import { DeleteIconButton } from '@app/components/buttons/DeleteIconButton';
import { AwsCalcInDto, AwsCalcOutDtoResponse } from '../types/calc.types';
import { CalcErrorResponse, SubmissionOutDto } from '@app/swagger-types';
import { AddIconButton } from '@app/components/buttons/AddIconButton';
import { StateSelect } from '@app/domain/sti/components/StateSelect';
import { ErrorsTable } from '@app/domain/sti/components/ErrorsTable';
import { formatStiAwsPrefilledValues } from '../utils/form.utils';
import { DragButton } from '@app/components/buttons/DragButton';
import { WarningButton } from '../components/WarningButton';
import { Tooltip } from '@app/components/tooltip/Tooltip';
import { ErrorButton } from '../components/ErrorButton';
import { Button } from '@app/components/buttons/button/Button';

const FORM_ID = 'aws-calculator';
const DROPPABLE_ID = 'stateInput';

interface Props {
  calcSubmissionByAws: (values: AwsCalcInDto) => Promise<AwsCalcOutDtoResponse>;
  awsCalcErrors: CalcErrorResponse | null;
  awsCalcResult?: AwsCalcOutDtoResponse;
  submissionData?: SubmissionOutDto;
  submissionId: string;
  isLoading: boolean;
}

export const SubmissionAwsCalculator: FC<Props> = ({
  calcSubmissionByAws,
  awsCalcErrors,
  awsCalcResult,
  submissionId,
  isLoading,
}) => {
  const [showErrors, setShowErrors] = useState(false);
  const [showWarnings, setShowWarnings] = useState(false);

  const { mutateAsync: updateStiAwsPrefill, isLoading: isUpdatingStiAwsPrefill } =
    useUpdateSubmissionStiAwsPrefill(submissionId);

  const { ncci } = useSubmissionCalculatorFormsContext();

  const { handleSubmit, control, watch, formState, setValue, reset } = useFormContext<AwsCalcValidationSchema>();

  const { isValid } = formState;

  const [focusedField, setFocusedField] = useState<{
    column: 'no' | 'state' | 'zipCode';
    rowLocalId: string;
  }>({
    column: 'no',
    rowLocalId: '',
  });

  const {
    fields = [],
    remove,
    move,
    insert,
  } = useFieldArray<AwsCalcValidationSchema>({
    control,
    name: 'stateInputs',
  });

  const handleShowErrors = useCallback(() => setShowErrors(true), []);

  const handleHideErrors = useCallback(() => setShowErrors(false), []);

  const handleShowWarnings = useCallback(() => setShowWarnings(true), []);

  const handleHideWarnings = useCallback(() => setShowWarnings(false), []);

  const errors = awsCalcErrors?.responseDetail;
  const warnings = awsCalcResult?.warnings;

  const resetFocusedField = useCallback(() => {
    setFocusedField({
      column: 'no',
      rowLocalId: '',
    });
  }, []);

  const handleDragStart = useCallback(() => resetFocusedField(), [resetFocusedField]);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination?.droppableId === DROPPABLE_ID) {
        move(result.source.index, result.destination.index);
      }
    },
    [move]
  );

  const handleDeleteRow = (index: number) => () => {
    remove(index);
    resetFocusedField();
  };

  const handleAddRow = (index: number) => () => {
    const positionToInsert = index + 1;

    const previousStateCode = watch().stateInputs?.[index].stateCode || '';

    const row: AwsStateInputSchemaType = {
      stateCode: previousStateCode,
      zipCode: '',
      ncci: null,
      payroll: '',
      rowLocalId: nanoid(),
    };

    insert(positionToInsert, row);

    setFocusedField({
      column: row.stateCode ? 'zipCode' : 'state',
      rowLocalId: row.rowLocalId,
    });
  };

  const handleTransformFieldToDecimalValue = useCallback(
    (field: keyof AwsCalcValidationSchema) => (e?: ChangeEvent<any>) => {
      if (e?.target.value) {
        if (Number.isInteger(Number(e.target.value))) {
          setValue(field, `${Number(e.target.value)}.00`);
        }
      }
    },
    [setValue]
  );

  const handleFieldBlur =
    (field: FieldPath<AwsCalcValidationSchema>) => (e?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e?.target.value) {
        let value = e.target.value.replace(/\$/g, '').replace(/,/g, '');

        if (Number.isInteger(Number(value))) {
          value = `${Number(value)}.00`;
          setValue(field, value);
        }
      }
    };

  const handlePayrollBlur = (index: number) => handleFieldBlur(`stateInputs.${index}.payroll`);

  return (
    <Paper className="flex w-full flex-col gap-2.5 rounded-lg bg-white p-6 shadow-card">
      <SubmissionCalculatorHeading isLoading={isLoading} />
      <div className="flex flex-col">
        <form
          id={FORM_ID}
          onSubmit={handleSubmit(async (data) => {
            calcSubmissionByAws(createAwsCalcDto(data));

            const result = await updateStiAwsPrefill(createAwsPrefillDto(data));

            reset(formatStiAwsPrefilledValues(result, ncci?.result || []));
          })}
        >
          <div className="grid grid-cols-[140px,4fr] items-end gap-x-4 gap-y-5">
            <TextField
              sx={{ '& .MuiInputBase-input.Mui-disabled': { backgroundColor: '#F5F5F5', color: '#00000099' } }}
              value="492110: Couriers and Express Delivery Services"
              className="col-span-2"
              label="NAICS Code"
              disabled
            />
            <ReactHookFormNumberField
              onFieldBlur={handleTransformFieldToDecimalValue('emr')}
              className="col-span-1"
              thousandSeparator=","
              disabled={isLoading}
              placeholder="N.NN"
              inputSize="large"
              control={control}
              decimalScale={2}
              label="EMR"
              name="emr"
              fullWidth
            />
            <ReactHookFormNumberField
              label="Years in Business"
              name="yearsInBusiness"
              className="col-span-1"
              thousandSeparator=","
              disabled={isLoading}
              control={control}
              inputSize="large"
              decimalScale={0}
              placeholder="N"
              fullWidth
            />
            <TextField
              sx={{ '& .MuiInputBase-input.Mui-disabled': { backgroundColor: '#F5F5F5', color: '#00000099' } }}
              value={`$${Number(
                watch().stateInputs.reduce((acc, curr) => acc + Number(curr.payroll), 0)
              ).toLocaleString()}`}
              label="Total Payroll"
              placeholder="$0"
              type="text"
              disabled
              className="col-span-1"
            />
            <div className="col-span-5 h-px bg-gray-200" />
            <div className="col-span-1 flex h-full items-center">
              <Typography className="col-span-1 mx-auto text-center font-medium">Most Recent Year</Typography>
            </div>
            <ReactHookFormNumberField
              name="recentMedicalClaims"
              label="Medical Claims"
              thousandSeparator=","
              allowNegative={false}
              className="col-span-1"
              disabled={isLoading}
              control={control}
              inputSize="large"
              decimalScale={0}
              placeholder="N"
              fullWidth
            />
            <ReactHookFormNumberField
              onFieldBlur={handleFieldBlur('recentTotalMedicalLosses')}
              name="recentTotalMedicalLosses"
              label="Total Medical Losses"
              className="col-span-1"
              thousandSeparator=","
              allowNegative={false}
              disabled={isLoading}
              placeholder="N.NN"
              control={control}
              inputSize="large"
              decimalScale={2}
              prefix="$"
              fullWidth
            />
            <ReactHookFormNumberField
              name="recentIndemnityClaims"
              label="Indemnity Claims"
              className="col-span-1"
              thousandSeparator=","
              allowNegative={false}
              disabled={isLoading}
              inputSize="large"
              control={control}
              decimalScale={0}
              placeholder="N"
              fullWidth
            />
            <ReactHookFormNumberField
              onFieldBlur={handleFieldBlur('recentTotalIndemnityLosses')}
              name="recentTotalIndemnityLosses"
              label="Total Indemnity Losses"
              className="col-span-1"
              thousandSeparator=","
              allowNegative={false}
              disabled={isLoading}
              placeholder="N.NN"
              control={control}
              inputSize="large"
              decimalScale={2}
              prefix="$"
              fullWidth
            />
            <div className="col-span-1 flex h-full items-center">
              <Typography className="mx-auto text-center font-medium">Previous Year</Typography>
            </div>
            <ReactHookFormNumberField
              name="previousMedicalClaims"
              label="Medical Claims"
              allowNegative={false}
              thousandSeparator=","
              className="col-span-1"
              disabled={isLoading}
              inputSize="large"
              control={control}
              decimalScale={0}
              placeholder="N"
              fullWidth
            />
            <ReactHookFormNumberField
              onFieldBlur={handleFieldBlur('previousTotalMedicalLosses')}
              name="previousTotalMedicalLosses"
              label="Total Medical Losses"
              className="col-span-1"
              thousandSeparator=","
              allowNegative={false}
              disabled={isLoading}
              placeholder="N.NN"
              inputSize="large"
              control={control}
              decimalScale={2}
              prefix="$"
              fullWidth
            />
            <ReactHookFormNumberField
              name="previousIndemnityClaims"
              label="Indemnity Claims"
              className="col-span-1"
              thousandSeparator=","
              allowNegative={false}
              disabled={isLoading}
              inputSize="large"
              control={control}
              decimalScale={0}
              placeholder="N"
              fullWidth
            />

            <ReactHookFormNumberField
              onFieldBlur={handleFieldBlur('previousTotalIndemnityLosses')}
              name="previousTotalIndemnityLosses"
              label="Total Indemnity Losses"
              className="col-span-1"
              thousandSeparator=","
              allowNegative={false}
              disabled={isLoading}
              placeholder="N.NN"
              inputSize="large"
              control={control}
              decimalScale={2}
              prefix="$"
              fullWidth
            />
            <div className="col-span-1 flex h-full items-center">
              <Typography className="mx-auto text-center font-medium ">Two Years Ago</Typography>
            </div>
            <ReactHookFormNumberField
              name="olderMedicalClaims"
              label="Medical Claims"
              thousandSeparator=","
              allowNegative={false}
              className="col-span-1"
              disabled={isLoading}
              inputSize="large"
              control={control}
              decimalScale={0}
              placeholder="N"
              fullWidth
            />
            <ReactHookFormNumberField
              onFieldBlur={handleFieldBlur('olderTotalMedicalLosses')}
              name="olderTotalMedicalLosses"
              label="Total Medical Losses"
              className="col-span-1"
              thousandSeparator=","
              allowNegative={false}
              disabled={isLoading}
              placeholder="N.NN"
              inputSize="large"
              control={control}
              decimalScale={2}
              prefix="$"
              fullWidth
            />
            <ReactHookFormNumberField
              name="olderIndemnityClaims"
              label="Indemnity Claims"
              className="col-span-1"
              thousandSeparator=","
              allowNegative={false}
              disabled={isLoading}
              inputSize="large"
              control={control}
              decimalScale={0}
              placeholder="N"
              fullWidth
            />
            <ReactHookFormNumberField
              onFieldBlur={handleFieldBlur('olderTotalIndemnityLosses')}
              name="olderTotalIndemnityLosses"
              label="Total Indemnity Losses"
              className="col-span-1"
              thousandSeparator=","
              allowNegative={false}
              disabled={isLoading}
              placeholder="N.NN"
              control={control}
              inputSize="large"
              decimalScale={2}
              prefix="$"
              fullWidth
            />
            <div className="col-span-5 h-px bg-gray-200" />
            <div className="col-span-5">
              <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                <Droppable droppableId={DROPPABLE_ID} direction="vertical">
                  {(droppableProvided) => (
                    <div ref={droppableProvided.innerRef} className="flex flex-col gap-3.5">
                      {fields.map((stateInput, index, arr) => {
                        const shouldFocusState =
                          focusedField.column === 'state' && stateInput.rowLocalId === focusedField.rowLocalId;
                        const shouldFocusZipCode =
                          focusedField.column === 'zipCode' && stateInput.rowLocalId === focusedField.rowLocalId;

                        return (
                          <Draggable key={index} index={index} draggableId={`${DROPPABLE_ID}.${index}`}>
                            {(draggableProvided) => (
                              <div
                                {...draggableProvided.draggableProps}
                                ref={draggableProvided.innerRef}
                                className="grid w-full items-center gap-x-4 gap-y-4 bg-white"
                                style={{
                                  gridTemplateColumns: '60px 160px 100px 2fr 170px 80px',
                                  ...draggableProvided.draggableProps.style,
                                }}
                              >
                                <div className="flex items-center justify-center">
                                  <DragButton {...draggableProvided.dragHandleProps} disabled={isLoading} />
                                </div>
                                <div className={index === 0 ? '-mt-[10px]' : undefined}>
                                  <StateSelect
                                    name={`stateInputs.${index}.stateCode`}
                                    key={stateInput.rowLocalId}
                                    focus={shouldFocusState}
                                    disabled={isLoading}
                                    control={control}
                                    index={index}
                                  />
                                </div>
                                <div className={index === 0 ? '-mt-[10px]' : undefined}>
                                  <ReactHookFormPatternField
                                    label={index === 0 ? 'Zip Code' : undefined}
                                    name={`stateInputs.${index}.zipCode`}
                                    key={stateInput.rowLocalId}
                                    focus={shouldFocusZipCode}
                                    disabled={isLoading}
                                    variant="outlined"
                                    control={control}
                                    inputSize="large"
                                    placeholder="N"
                                    format="#####"
                                    fullWidth
                                  />
                                </div>
                                <div className={index === 0 ? '-mt-[10px]' : undefined}>
                                  <PayrollClassCodeAutocomplete
                                    label={index === 0 ? 'Payroll Class Code' : undefined}
                                    name={`stateInputs.${index}.ncci`}
                                    key={stateInput.rowLocalId}
                                    disabled={isLoading}
                                    control={control}
                                    aws
                                  />
                                </div>
                                <div className={index === 0 ? '-mt-[10px]' : undefined}>
                                  <ReactHookFormNumberField
                                    label={index === 0 ? 'Payroll Amount' : undefined}
                                    onFieldBlur={handlePayrollBlur(index)}
                                    name={`stateInputs.${index}.payroll`}
                                    key={stateInput.rowLocalId}
                                    thousandSeparator=","
                                    disabled={isLoading}
                                    placeholder="Amount"
                                    variant="outlined"
                                    control={control}
                                    inputSize="large"
                                    decimalScale={2}
                                    prefix="$"
                                    fullWidth
                                  />
                                </div>
                                <div className="flex items-center justify-start">
                                  <Tooltip
                                    text="To add a new payroll entry under a different class code click the plus button."
                                    title="Add payroll entry"
                                  >
                                    <AddIconButton disabled={isLoading} onClick={handleAddRow(index)} />
                                  </Tooltip>
                                  {arr.length > 1 && (
                                    <Tooltip
                                      text="Click to delete the selected payroll entry."
                                      title="Delete payroll entry"
                                    >
                                      <DeleteIconButton disabled={isLoading} onClick={handleDeleteRow(index)} />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </form>
        <div className="mt-8 flex w-full items-center justify-center gap-3">
          <Button
            disabled={!isValid || isUpdatingStiAwsPrefill}
            variant="contained"
            loading={isLoading}
            className="w-60"
            color="primary"
            form={FORM_ID}
            type="submit"
          >
            Calculate
          </Button>
          <ErrorButton errors={errors} show={showErrors} handleShow={handleShowErrors} isLoading={isLoading} />
          <WarningButton
            handleShow={handleShowWarnings}
            isLoading={isLoading}
            warnings={warnings}
            show={showWarnings}
          />
        </div>
        {showErrors && errors && !isLoading ? (
          <div className="mt-8 flex w-full justify-center">
            <ErrorsTable errors={errors} open={showErrors} onClose={handleHideErrors} />
          </div>
        ) : null}
        {showWarnings && warnings && !isLoading ? (
          <div className="mt-8 flex w-full justify-center">
            <ErrorsTable
              messageColumnLabel="Warning Details"
              onClose={handleHideWarnings}
              codeColumnLabel="Code"
              open={showWarnings}
              errors={warnings}
            />
          </div>
        ) : null}
      </div>
    </Paper>
  );
};
