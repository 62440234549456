import { Blocker, BlockerFunction } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider } from 'react-hook-form';
import { Skeleton } from '@mui/material';
import { FC, useCallback } from 'react';

import { useUpdateSubmissionStiAwsPrefill, useUpdateSubmissionStiFullPrefill } from '../api/submission.api.hooks';
import { ESubmissionTab, useSubmissionTabQueryParam } from '../hooks/useSubmissionTabQueryParam';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { GROUPS_WITH_ACCESS_TO_SUBMISSION_INSIGHTS } from '@app/domain/sti/const/sti-access.const';
import { createAwsPrefillDto, createFullPrefillDto } from '@app/domain/sti/api/sti-api.utils';
import { NavigationBlocker } from '@app/components/navigation-blocker/NavigationBlocker';
import { SubmissionCalculatorLastMileTab } from './SubmissionCalculatorLastMileTab';
import { SubmissionCalculatorStandardTab } from './SubmissionCalculatorStandardTab';
import { useSubmissionCalcPrefill } from '../hooks/useSubmissionCalcPrefill';
import { HAS_ACCESS_TO_SUBMISSION_TABS } from '@app/environment/typed-env';
import { SubmissionLossSummaryTab } from './SubmissionLossSummaryTab';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { SubmissionLossReviewTab } from './SubmissionLossReviewTab';
import { SubmissionDocumentsTab } from './SubmissionDocumentsTab';
import { useRoleRequired } from '@app/auth/useRoleRequired.hook';
import { SubmissionInsightsTab } from './SubmissionInsightsTab';
import { EOutcomeType } from '@app/swagger-types';
import {
  useFullSubmissionCalculatorForm,
  useAwsSubmissionCalculatorForm,
} from '@app/domain/sti/hooks/useSubmissionCalculatorForm';

export enum ESubmissionSafeTierRatingTab {
  STANDARD = 'Standard',
  LAST_MILE = 'Last Mile',
}

interface Props {
  submissionId: string;
  setInsightsIndicated: (value: boolean) => void;
}

export const SubmissionTabs: FC<Props> = ({ submissionId, setInsightsIndicated }) => {
  const { mutateAsync: updateFullPrefill } = useUpdateSubmissionStiFullPrefill(submissionId);
  const { mutateAsync: updateAwsPrefill } = useUpdateSubmissionStiAwsPrefill(submissionId);

  const queryClient = useQueryClient();

  const fullFormMethods = useFullSubmissionCalculatorForm();
  const awsFormMethods = useAwsSubmissionCalculatorForm();

  const { getValues: getFullFormValues } = fullFormMethods;
  const { getValues: getAwsFormValues } = awsFormMethods;

  const userHasAccessToInsights = useRoleRequired({ oneOf: GROUPS_WITH_ACCESS_TO_SUBMISSION_INSIGHTS });

  const { isCalcEditing, calcTab } = useSubmissionCalculatorFormsContext();

  const [tab] = useSubmissionTabQueryParam();
  const {
    formState: { isDirty: isFullFormDirty },
  } = fullFormMethods;

  const {
    formState: { isDirty: isAwsFormDirty },
  } = awsFormMethods;

  const isDirty = isFullFormDirty || isAwsFormDirty;

  const blockerFn: BlockerFunction = useCallback(
    (args) => {
      const isSamePathname = args.currentLocation.pathname === args.nextLocation.pathname;

      return !isSamePathname && isDirty;
    },
    [isDirty]
  );

  const onConfirm = async () => {
    if (isCalcEditing) {
      return;
    }

    if (calcTab === ESubmissionSafeTierRatingTab.STANDARD) {
      await updateFullPrefill(createFullPrefillDto(getFullFormValues()));
      queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_STI_FULL_PREFILL, submissionId]);
    }

    if (calcTab === ESubmissionSafeTierRatingTab.LAST_MILE) {
      await updateAwsPrefill(createAwsPrefillDto(getAwsFormValues()));
      queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_STI_AWS_PREFILL, submissionId]);
    }
  };

  const onCancel = (blocker: Blocker) => {
    if (isCalcEditing) {
      blocker.reset?.();
    } else {
      blocker.proceed?.();
    }
  };

  const { isOutcomeGiDataFetching, isOutcomeFetching } = useSubmissionCalcPrefill(
    submissionId,
    calcTab === ESubmissionSafeTierRatingTab.STANDARD ? EOutcomeType.FULL_CALC : EOutcomeType.AWS_CALC,
    { full: fullFormMethods.reset, aws: awsFormMethods.reset },
    { full: fullFormMethods.watch(), aws: awsFormMethods.watch() }
  );

  return (
    <>
      {tab === ESubmissionTab.DOCUMENTS && (
        <SubmissionDocumentsTab setInsightsIndicated={setInsightsIndicated} submissionId={submissionId} />
      )}
      {HAS_ACCESS_TO_SUBMISSION_TABS && (
        <>
          {tab === ESubmissionTab.LOSS_REVIEW && <SubmissionLossReviewTab submissionId={submissionId} />}
          {tab === ESubmissionTab.LOSS_SUMMARY && <SubmissionLossSummaryTab submissionId={submissionId} />}
          {tab === ESubmissionTab.SAFE_TIER_RATING && (
            <>
              {isOutcomeGiDataFetching || isOutcomeFetching ? (
                <div className="flex flex-col gap-8">
                  <Skeleton variant="rounded" width="100%" height={132} />
                  <Skeleton variant="rounded" width="100%" height={600} />
                </div>
              ) : (
                <>
                  {calcTab === ESubmissionSafeTierRatingTab.STANDARD && (
                    <FormProvider {...fullFormMethods}>
                      <SubmissionCalculatorStandardTab submissionId={submissionId} />
                    </FormProvider>
                  )}
                  {calcTab === ESubmissionSafeTierRatingTab.LAST_MILE && (
                    <FormProvider {...awsFormMethods}>
                      <SubmissionCalculatorLastMileTab submissionId={submissionId} />
                    </FormProvider>
                  )}
                </>
              )}
            </>
          )}
          {userHasAccessToInsights && tab === ESubmissionTab.INSIGHTS && (
            <SubmissionInsightsTab setInsightsIndicated={setInsightsIndicated} submissionId={submissionId} />
          )}
        </>
      )}
      <NavigationBlocker
        text={
          isCalcEditing
            ? 'You have unsaved changes. If you leave, your changes will be lost, continue?'
            : 'You have unsaved changes. Would you like to save your changes, continue?'
        }
        title="Unsaved Changes Detected"
        cancelText={isCalcEditing ? 'Cancel' : 'Discard Changes'}
        confirmText={isCalcEditing ? 'Continue ' : 'Save Changes'}
        onConfirm={onConfirm}
        onCancel={onCancel}
        blockerFn={blockerFn}
      />
    </>
  );
};
