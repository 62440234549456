import { FC } from 'react';
import clsx from 'clsx';

import { Button } from '@app/components/buttons/button/Button';
import { SubmissionInsightOutDto } from '@app/swagger-types';
import { clsxm } from '@app/styles/clsxm';

interface Props {
  handleGenerateInsights: () => Promise<SubmissionInsightOutDto>;
  isGenerateDisabled: boolean;
  isDocumentsReady: boolean;
  isGenerating: boolean;
}

export const SubmissionGenerateInsights: FC<Props> = ({
  handleGenerateInsights,
  isGenerateDisabled,
  isDocumentsReady,
  isGenerating,
}) => {
  return (
    <>
      <Button
        disabled={isGenerateDisabled}
        onClick={handleGenerateInsights}
        className={clsxm(
          'mx-auto mt-16 w-fit rounded-md border-none text-white shadow-md',
          isGenerateDisabled ? 'bg-[#1F293799]' : 'bg-[#1F2937]'
        )}
      >
        Generate Insights
      </Button>
      <div
        className={clsx(
          'mx-auto mt-10 w-full max-w-[825px] text-center text-[#00000099]',
          isGenerating && 'animate-pulse'
        )}
      >
        {isGenerating ? (
          <p>Generating Insights</p>
        ) : (
          <div className="flex flex-col gap-2">
            {!isDocumentsReady ? (
              <p>Upload submission documents to enable AI generated Insights</p>
            ) : (
              <>
                <p>Select “Generate Insights” to get an AI analysis of this submission.</p>
                <p>Before generating, ensure all available submission documents have been uploaded.</p>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
